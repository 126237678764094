
  import { Component, Watch } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import { plainToInstance } from 'class-transformer'
  import { Form } from '@/entities/public/Resource/metadata'
  import { Lead } from '@/entities/crm'
  import InitialFields from '@/components/forms/fields/InitialFields.vue'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import StockPrices from '@/components/toolkit/details/row/custom/StockPrices.vue'
  import AutoHeader from '@/components/toolkit/details/row/expandable/auto/header.vue'
  import Simple from '@/components/toolkit/details/row/simple.vue'
  import { Appraisal, Negotiation, ProcessTrafficTicket } from '@/entities/purchase'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Process } from '@/entities/settings'
  import { isValidNumber, parseToNumber } from '@/utils/general'
  import { NegotiationView } from '@/components/forms/view/NegotiationView'
  import TrafficTicketComponent from '@/components/forms/trafficTicket/TrafficTicketComponent.vue'
  import AutoResume from '@/components/forms/AutoResume.vue'

@Component({
  components: {
    AutoResume,
    TrafficTicketComponent,
    GFiles,
    Simple,
    StockPrices,
    FieldTitle,
    LinkedPerson,
    InitialFields,
    LinkedAuto,
    BaseForm,
    AutoHeader,
  },
})
  export default class SupervisorNegotiationForm extends NegotiationView {
  processTrafficTicket = plainToInstance(ProcessTrafficTicket, {})
  negotiation = plainToInstance(Negotiation, {})
  appraisal: Appraisal = plainToInstance(Appraisal, {})
  lead = plainToInstance(Lead, {})
  title = ''
  loadedPhoto = false
  errorLegalApproved = ''
  errorExecutiveUpdate = ''
  checkboxMessage = ''
  process: Process = plainToInstance(Process, {})
  idProcess = null
  showDetail = false
  isPriceExpectedLower = false
  penaltiesErrorMessage = ''
  processTrafficTicketSelected = null
  displayConsignment = false
  openTrafficTicket = false
  idProcessTrafficTicket = null
  loadingForm = false
  declare $refs: {
    form: HTMLFormElement
  };

  penalties: ProcessTrafficTicket[] = []
  metadata = {}
  appraisalMetadata = {}
  formData = {
    expectedPrice: null,
    expectedPriceConsignment: null,
    cav: [],
    penalties: [],
    legalReport: [],
    linkLegalReport: null,
    legalApproved: null,
    executiveUpdate: null,
    options: null,
  }

  fields = {
    expectedPrice: {
      properties: {},
      rules: this.fieldRequired,
    },
    cav: {
      properties: {
        label: '',
        accept: '',
        fileTypeId: null,
        name: '',
        multiple: false,
        appendOuterIcon: '',
      },
    },
    penalties: {
      properties: {
        label: '',
        accept: '',
        fileTypeId: null,
        name: '',
        multiple: false,
        appendOuterIcon: '',
      },
    },
    legalReport: {
      properties: {
        label: '',
        accept: '',
        fileTypeId: null,
        name: '',
        multiple: false,
        appendOuterIcon: '',
      },
    },
    linkLegalReport: {
      properties: {},
      rules: this.fieldRequired,
    },
    paymentProof: {
      properties: {
        label: '',
        accept: '',
        fileTypeId: null,
        name: '',
        multiple: false,
        appendOuterIcon: '',
      },
    },
    button1: '',
    button2: '',
  }

  processTrafficStatus = {
    paid: null,
    pending: null,
  }

  showTicketDialog: boolean = false

  async mounted () {
    await this.setMetadata()

    const { uid, title, metadata } = this

    if (uid) {
      await this.getNegotiationInfo(uid)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadata, title, Boolean(this.negotiation?.id))
    }
  }

  showTicket (ticket) {
    this.processTrafficTicket = ticket

    this.showTicketDialog = true
  }

  get disableFields () {
    const { negotiation } = this

    return negotiation?.closingReason
  }

  async getNegotiationInfo (id) {
    const negotiation = await this.fetchData({
      query: { name: 'fetch', model: 'Negotiation', params: { id } },
      force: true,
    })
    this.negotiation = negotiation
    if (negotiation.status?.isAppealAnswered) {
      this.formData.expectedPrice = negotiation?.negotiationResponse?.value
      this.formData.expectedPriceConsignment = negotiation?.negotiationResponseConsignment?.value
    }
    this.appraisal = negotiation.inspection.appraisal
    if (negotiation?.status?.isApproved) {
      this.formData.legalApproved = negotiation?.status?.isApproved
    }
    if (negotiation?.status?.isRejected) {
      this.formData.legalApproved = false
    }
    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'negotiation' } } },
    })
    this.setProperties(fileInfo, 'penalty_certificate', 'penalties')
    this.setProperties(fileInfo, 'cav', 'cav')
    this.setProperties(fileInfo, 'legal_report', 'legalReport')
    await this.setTrafficTicketProperties()
    await this.setFilesData(negotiation)

    this.setDetails()
  }

  async setTrafficTicketProperties () {
    const trafficTicket = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'traffic_ticket' } } },
    })

    this.setProperties(trafficTicket, 'payment_proof', 'paymentProof')

    const process = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { process: { table_name: { _eq: 'process_traffic_ticket' } } },
    })

    this.processTrafficStatus.paid = process.find(p => p.isPaid)?.id
    this.processTrafficStatus.pending = process.find(p => p.isPending)?.id
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)

    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
    }
  }

  async setFilesData (negotiation) {
    const { idProcess } = this
    const processAppraisal = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'appraisal' } },
    })

    const { cav, report, penalties } = await this.findAssociatedFiles(idProcess, negotiation.id)

    this.formData.cav = cav?.length ? [cav[0]] : []
    this.formData.legalReport = report?.length ? [report[0]] : []
    if (report?.[0]?.file?.sourceLink) {
      this.formData.linkLegalReport = report?.[0]?.file?.sourceLink
    }
    this.formData.penalties = penalties?.length ? [penalties[0]] : []
    this.penalties = await this.getPenalties(this.formData?.penalties?.[0]?.id)

    const {
      cav: cavAppraisal,
      report: reportAppraisal,
    } = await this.findAssociatedFiles(processAppraisal[0].id, negotiation?.inspection?.appraisal?.id)

    if (!cav?.length) {
      this.formData.cav = cavAppraisal?.length ? [cavAppraisal[0]] : []
    }

    if (!report?.length) {
      this.formData.legalReport = reportAppraisal?.length ? [reportAppraisal[0]] : []
      if (reportAppraisal?.[0]?.file?.sourceLink) {
        this.formData.linkLegalReport = reportAppraisal?.[0]?.file?.sourceLink
      }
    }
  }

  async findAssociatedFiles (idProcess, id) {
    if (!id) return {}
    const cav = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { file_type: { name: { _eq: 'cav_negotiation' } } } }] },
      force: true,
    })

    if (cav.length) {
      this.negotiation.cavValidation = {
        expirationDate: cav[0].file.expirationDate,
        validations: cav[0].validation,
      }
    }

    const report = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { file_type: { name: { _eq: 'legal_report' } } } }] },
      force: true,
    })

    const penalties = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { file_type: { name: { _eq: 'penalty_certificate' } } } }] },
      force: true,
    })

    return {
      cav,
      report,
      penalties,
    }
  }

  saveBackup () {
    const { backup, formData } = this

    if (backup) {
      backup.negotiationForm = { ...formData }
      this.setBackup(backup)
    } else {
      this.setBackup({ negotiationForm: { ...formData } })
    }
  }

  async setMetadata () {
    const { metadata: metadataAppraisalSupervisor } = this.getForm('Appraisal', 'supervisor_appraisal')
    const { fields: fieldsAppraisal } = metadataAppraisalSupervisor as Form
    this.appraisalMetadata = metadataAppraisalSupervisor
    const { metadata } = this.getForm('Negotiation', 'supervisor_negotiation')
    const { fields, form } = metadata as Form
    this.metadata = metadata
    this.title = form.title
    this.fields.expectedPrice.properties = fields.expectedPrice?.properties
    Object.assign(this.fields.cav.properties, fields.cav?.properties)
    Object.assign(this.fields.legalReport.properties, fields.legalReport?.properties)
    Object.assign(this.fields.penalties.properties, fields.penalties?.properties)
    this.fields.linkLegalReport.properties = fields.linkLegalReport?.properties

    this.fields.button1 = fieldsAppraisal.button1.properties.label
    this.fields.button2 = fieldsAppraisal.button2.properties.label
    await this.setFilesFieldsData()
    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: {
        table_name: { _eq: 'purchase_order' },
      },
    }))[0]

    this.displayConsignment = process?.config?.displayConsignment
  }

  async setFilesFieldsData () {
    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'negotiation' } } },
    })

    this.setProperties(fileInfo, 'penalty_certificate', 'penalties')
    this.setProperties(fileInfo, 'cav', 'cav')
    this.setProperties(fileInfo, 'legal_report', 'legalReport')

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'negotiation' } },
    })

    this.idProcess = process[0].id

    const trafficProcess = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'traffic_ticket' } },
    })
    this.idProcessTrafficTicket = trafficProcess[0].id
  }

  setDetails () {
    const { metadata, negotiation } = this
    this.metadata = {
      data: negotiation,
      metadata,
    }

    this.showDetail = (Boolean(negotiation?.id))
  }

  validationTrafficTickets () {
    const { negotiation, penalties } = this

    return negotiation?.status?.isLegalReview && penalties.some(penalty => penalty.isPending)
  }

  filesValidation () {
    const { formData, showFields } = this
    if (!showFields) return false

    const fields = ['cav', 'penalties', 'legalReport']

    for (const field of fields) {
      if (formData[field].length === 0) {
        if (this.$refs[field]) {
          this.$refs[field].errorMessage = 'Debe subir un archivo'
        }
        return true
      } else {
        this.$refs[field].errorMessage = ''
      }
    }
  }

  filesWatcher () {
    const { formData: { cav, penalties, legalReport } } = this

    return {
      cav,
      penalties,
      legalReport,
    }
  }

  validation () {
    const { formData: { legalApproved }, showFields } = this
    if (!showFields) return false

    if (legalApproved === null) {
      this.errorLegalApproved = 'Debe seleccionar una opción'
      return true
    }
    this.errorLegalApproved = ''
    return false
  }

  validateExecutiveUpdate () {
    const { formData: { executiveUpdate, legalApproved }, negotiation } = this

    if (!negotiation?.agreedAmount) return false
    if (legalApproved) return false
    if (executiveUpdate === null) {
      this.errorExecutiveUpdate = 'Debe seleccionar una opción'
      return true
    }
    this.errorExecutiveUpdate = ''
    return false
  }

  validateExecutiveOptions () {
    const { formData: { options, executiveUpdate }, negotiation } = this
    if (!negotiation?.agreedAmount) return false
    if (!executiveUpdate) return false

    if (options === null || !options?.length) {
      this.checkboxMessage = 'Debe seleccionar al menos una opción'
      return true
    }
    this.checkboxMessage = ''
    return false
  }

  async send () {
    const { negotiation, missingPenalties, formData: { legalApproved } } = this

    if (negotiation?.status?.isLegalReview && missingPenalties && legalApproved !== false && negotiation?.agreedAmount) {
      this.penaltiesErrorMessage = 'Tiene multas por validar'
      return
    }

    if (!this.$refs.form.validate() || this.filesValidation() || this.validation() || this.validateExecutiveUpdate() || this.validateExecutiveOptions()) {
      return
    }
    this.loadingForm = true
    await this.handleNegotiationSend()
  }

  async handleNegotiationSend () {
    const { showFields, negotiation, idEmployee, formData } = this
    if (!showFields) {
      await this.insertNegotiationResponseSupervisor(formData, negotiation, idEmployee)
    } else {
      await this.negotiationHandleStatus(this)
    }

    await this.close()
  }

  validateExpectedPrice (val, consignment = false) {
    const { negotiation: { negotiationResponse, negotiationResponseConsignment } } = this
    if (!isValidNumber(val)) return null
    if (!consignment) {
      this.isPriceExpectedLower = parseToNumber(val) < parseToNumber(negotiationResponse?.value)
    } else {
      this.isPriceExpectedLower = parseToNumber(val) < parseToNumber(negotiationResponseConsignment?.value)
    }
  }

  get change () {
    const { formData, isPriceExpectedLower, loadedPhoto } = this

    return JSON.stringify(formData) + JSON.stringify(isPriceExpectedLower) + JSON.stringify(loadedPhoto)
  }

  @Watch('filesWatcher', { deep: true, immediate: true })
  onFilesChange (val) {
    const fields = ['cav', 'penalties', 'legalReport']

    for (const field of fields) {
      if (val?.[field]?.length > 0 && this.$refs[field].errorMessage === 'Debe subir un archivo') {
        this.$refs[field].errorMessage = ''
      }
    }
  }

  get showFields () {
    const { negotiation } = this

    return negotiation?.status?.isLegalReview || negotiation?.status?.isApproved
  }

  get isApproved () {
    const { negotiation } = this
    return Boolean(negotiation?.status?.isApproved)
  }

  get hideFields () {
    const { negotiation } = this

    return !negotiation?.agreedAmount
  }

  get isReadingFile () {
    const { negotiation } = this

    return Boolean(negotiation?.status?.isReadingFile) || Boolean(negotiation?.status?.isToUpdating)
  }

  get isPending () {
    const { negotiation } = this

    return Boolean(negotiation?.status?.isPending)
  }

  get disabledSourceLink () {
    const { formData: { legalReport } } = this

    if (legalReport.length) {
      return Boolean(legalReport[0]?.id && legalReport[0]?.file?.sourceLink)
    }
    return null
  }

  @Watch('formData.legalApproved', { immediate: true })
  onLegalApproved (val) {
    if (val !== null) {
      this.errorLegalApproved = ''
    }
  }

  editPenalty (item: ProcessTrafficTicket) {
    this.penaltiesErrorMessage = ''
    this.processTrafficTicketSelected = item
    this.openTrafficTicket = true
  }

  goToButton (field) {
    const { appraisalMetadata, stock } = this
    const { fields } = appraisalMetadata as Form

    const { init: { value: { url } } } = fields[field]

    this.openPortal(url, stock, false)
  }

  @Watch('openTrafficTicket', { immediate: true })
  async onOpenTrafficTicketChange (val) {
    if (!val) {
      if (this.formData?.penalties?.length) {
        this.penalties = await this.getPenalties(this.formData?.penalties?.[0]?.id)
      }
      this.processTrafficTicketSelected = null
    }
  }

  get isClosed () {
    const { negotiation } = this

    return Boolean(negotiation.closingReason)
  }

  get missingPenalties () {
    const { penalties } = this

    return penalties?.some(penalty => penalty.status.isToConfirm)
  }

  get pendingPenalties () {
    const { penalties } = this

    return penalties?.some(penalty => penalty.status.isPending)
  }

  get stock () {
    const { appraisal } = this

    return {
      auto: appraisal?.deal?.auto,
    }
  }

  get deal () {
    const { negotiation } = this

    if (!negotiation?.inspection?.deal) return null

    return negotiation?.inspection?.deal
  }

  photoLoaded () {
    this.loadedPhoto = !this.loadedPhoto
  }

  get displayPurchase () {
    const { maxMileage, negotiation } = this

    return this.displayPurchaseFields(negotiation?.inspection?.appraisal?.kms, maxMileage)
  }

  get auto () {
    const { appraisal } = this

    return appraisal?.deal?.auto
  }

  async openAppraisalLink () {
    const { auto, appraisal } = this

    if (appraisal?.link) {
      this.openLink(appraisal.link)
      return
    }

    await this.buildLinkChileAutos(auto)
  }
  }
